import { autorun, when, runInAction, computed } from 'mobx';
import { IUser } from '@wix/yoshi-flow-editor';
import { ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward as LoyaltyReward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyEarningRule, Status as EarningRuleStatus } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';

import model from './model';
import { ElementId, DISCOUNT_REWARD_COUPON_NAME, ICON_BASE_URL } from './constants';
import {
  DiscountRewardConfig,
  NextRewardType,
  applyDiscountReward,
  calculateEarnPointsAmount,
  calculateNextRewardDetails,
  createBiLogger,
  defaultState,
  getCheckout,
  getDiscountRewardConfig,
  getLoyaltyAccount,
  getLoyaltyEarningRules,
  getLoyaltyProgram,
  getLoyaltyRewards,
  promptLogin,
} from './viewer';
import { createCurrencyFormatter, createNumberFormatter } from '../../utils';

export default model.createController(({ initState, $bind, $widget, $props, flowAPI, $w }) => {
  const { state } = initState(defaultState);
  const biLogger = createBiLogger(flowAPI.bi);
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isSSR } = flowAPI.environment;
  const { t } = flowAPI.translations;
  const { errorMonitor } = flowAPI;
  let refreshCheckout: (() => Promise<void>) | undefined;

  const formatNumber = createNumberFormatter(flowAPI);
  const formatCurrency = createCurrencyFormatter(flowAPI);

  const isValidPointsNumber = (value: string) => /^[0-9]+$/.test(value);

  const loadRemoteData = async <T extends unknown[]>(
    requestPromises: T,
  ): Promise<{ [P in keyof T]: Awaited<T[P]> }> => {
    const pendingRequestsCount = requestPromises.length;

    try {
      state.pendingRequestsCount += pendingRequestsCount;
      return await Promise.all(requestPromises);
    } catch (error) {
      state.hasFailedToLoad = true;
      errorMonitor?.captureException(error as Error);
      console.error(error);
    } finally {
      state.pendingRequestsCount -= pendingRequestsCount;
    }

    return [] as { [P in keyof T]: Awaited<T[P]> };
  };

  const loadCheckout = async () => {
    const [checkout] = await loadRemoteData([getCheckout(flowAPI, $props.checkoutId)]);
    state.checkout = checkout;
  };

  return {
    async pageReady() {
      const mainStates = $w(`#${ElementId.MainStates}`);
      const contentStates = $w(`#${ElementId.ContentStates}`);
      const discountRewardPointsInput = $w(`#${ElementId.DiscountRewardPointsInput}`);
      const programIcon = $w(`#${ElementId.ProgramIcon}`);

      const isLoading = computed<boolean>(() => state.pendingRequestsCount > 0);

      const activeRewards = computed<LoyaltyReward[]>(
        () => state.loyaltyRewards?.filter(({ active }) => !!active) ?? [],
      );

      const activeEarningRules = computed<LoyaltyEarningRule[]>(
        () => state.loyaltyEarningRules?.filter(({ status }) => status === EarningRuleStatus.ACTIVE) ?? [],
      );

      const activeDiscountReward = computed<LoyaltyReward | undefined>(() =>
        activeRewards.get().find(({ type }) => type === RewardType.DISCOUNT_AMOUNT),
      );

      const discountRewardConfig = computed<DiscountRewardConfig>(() =>
        getDiscountRewardConfig(activeDiscountReward.get(), state.loyaltyAccount),
      );

      const customPointsName = computed<string | undefined>(
        () => state.loyaltyProgram?.pointDefinition?.customName ?? undefined,
      );

      const userPointsAmount = computed<number>(() => state.loyaltyAccount?.points?.balance ?? 0);

      // Amount of points the user will earn if he completes this checkout order
      const earnPointsAmount = computed<number>(() => {
        return state.checkout
          ? calculateEarnPointsAmount(activeEarningRules.get(), state.checkout, state.loyaltyAccount)
          : 0;
      });

      // Details about next available reward (includes amount of points the user is missing etc).
      const nextRewardDetails = computed(() => {
        return calculateNextRewardDetails(activeRewards.get(), state.loyaltyAccount);
      });

      const isProgramActive = computed<boolean>(() => state.loyaltyProgram?.status === ProgramStatus.ACTIVE);
      const canEarnPoints = computed<boolean>(() => !!earnPointsAmount.get());
      const canRedeemPoints = computed<boolean>(() => !!discountRewardConfig.get().discount);

      // Program is available when user can redeem or earn points
      const isProgramAvailable = computed<boolean>(
        () => !state.hasFailedToLoad && isProgramActive.get() && (canRedeemPoints.get() || canEarnPoints.get()),
      );

      const maxDiscountRewardPointsAmount = computed<number>(() => {
        const { costInPoints, discountPerPoint } = discountRewardConfig.get();
        const orderTotalAmount = parseFloat(state.checkout?.priceSummary?.total?.amount ?? '0');
        const maxValueForOrder = discountPerPoint ? Math.ceil(orderTotalAmount / discountPerPoint) : 0;

        return Math.min(userPointsAmount.get(), Math.max(maxValueForOrder, costInPoints));
      });

      const discountRewardErrorMessage = computed<string | null>(() => {
        const value = state.discountRewardPointsValue.trim();
        const { costInPoints } = discountRewardConfig.get();

        if (state.hasFailedToApplyDiscountReward) {
          return t('discount-reward.error.unknown-error');
        } else if (state.showDiscountRewardOtherCouponError) {
          return t('discount-reward.error.other-coupon-applied');
        } else if (value) {
          if (!isValidPointsNumber(value)) {
            return t('discount-reward.error.enter-points-number');
          } else {
            const pointsValue = parseInt(value, 10);
            const minValue = costInPoints;
            const maxValue = maxDiscountRewardPointsAmount.get();

            if (pointsValue < minValue) {
              return t('discount-reward.error.enter-points-above', { minValue });
            } else if (pointsValue > maxValue) {
              return t('discount-reward.error.enter-points-below', { maxValue });
            }
          }
        }

        return null;
      });

      const isValidDiscountRewardPointsValue = computed<boolean>(
        () =>
          state.hasFailedToApplyDiscountReward || // Allow to retry on unknown error
          (!discountRewardErrorMessage.get() && !!state.discountRewardPointsValue.trim()),
      );

      const discountRewardPoints = computed<number | null>(() => {
        const value = state.discountRewardPointsValue.trim();
        return isValidPointsNumber(value) ? parseInt(value, 10) : null;
      });

      const isDiscountRewardErrorVisible = computed<boolean>(
        () => state.isDiscountRewardFormTouched && !!discountRewardErrorMessage.get(),
      );

      const isOurCouponCodeApplied = computed<boolean>(
        () =>
          state.checkout?.appliedDiscounts?.some(({ coupon }) => coupon?.name === DISCOUNT_REWARD_COUPON_NAME) ?? false,
      );

      const isOtherCouponCodeApplied = computed<boolean>(
        () => !!state.checkout?.appliedDiscounts?.some(({ coupon }) => !!coupon) && !isOurCouponCodeApplied.get(),
      );

      state.isLoggedIn = wixCodeApi.user.currentUser.loggedIn;
      wixCodeApi.user.onLogin((user: IUser) => {
        state.isLoggedIn = user.loggedIn;
      });

      $widget.onPropsChanged((oldProps, newProps) => {
        // HACK: There is no good way to detect external changes to checkout page yet (for example, when coupon is
        // removed or added outside of our widget). As a workaround we are using this undocumented (and probably
        // not intended) behaviour where `$widget.onPropsChanged()` is fired when there are any external changes.
        const shouldReloadCheckout =
          oldProps.checkoutId === newProps.checkoutId &&
          oldProps.stepId === newProps.stepId &&
          oldProps.slotId === newProps.slotId &&
          !!state.checkout &&
          state.isLoggedIn &&
          isProgramAvailable.get() &&
          canRedeemPoints.get();

        if (shouldReloadCheckout) {
          loadCheckout();
          state.showDiscountRewardOtherCouponError = false;
        }
      });

      // State changes
      autorun(() => mainStates.changeState(isLoading.get() ? ElementId.LoadingState : ElementId.LoadedState));
      autorun(() => {
        if (isLoading.get()) {
          return;
        }

        if (isOurCouponCodeApplied.get()) {
          contentStates.changeState(ElementId.CodeAppliedState);
        } else if (state.isLoggedIn) {
          if (nextRewardDetails.get().missingUserPoints) {
            contentStates.changeState(ElementId.NotEnoughPointsState);
          } else {
            contentStates.changeState(ElementId.DiscountRewardState);
          }
        } else {
          contentStates.changeState(ElementId.NotLoggedInState);
        }
      });

      // Load checkout remote data when checkout ID is provided
      when(
        () => !!$props.checkoutId && !isSSR,
        async () => {
          await loadCheckout();

          // We start rendering with one pending request state (to prevent initial content flicker).
          // Also note that $props.checkoutId is not provided in SSR - so in SSR we just render a loader.
          state.pendingRequestsCount--;
        },
      );

      // Load required remote data (for both user logged out and logged in flows)
      (async () => {
        if (isSSR) {
          return;
        }

        const [loyaltyProgram, loyaltyEarningRules, loyaltyRewards] = await loadRemoteData([
          getLoyaltyProgram(flowAPI),
          getLoyaltyEarningRules(flowAPI),
          getLoyaltyRewards(flowAPI),
        ]);

        runInAction(() => {
          state.loyaltyProgram = loyaltyProgram;
          state.loyaltyEarningRules = loyaltyEarningRules;
          state.loyaltyRewards = loyaltyRewards;
        });
      })();

      // Load required remote data when user is logged in
      when(
        () => state.isLoggedIn && !isSSR,
        async () => {
          const [loyaltyAccount] = await loadRemoteData([getLoyaltyAccount(flowAPI)]);
          state.loyaltyAccount = loyaltyAccount;
        },
      );

      when(
        () => !!state.loyaltyProgram?.pointDefinition?.icon?.url,
        () => {
          programIcon.src = `${ICON_BASE_URL}/${state.loyaltyProgram!.pointDefinition!.icon!.url}`;
        },
      );

      $bind(`#${ElementId.ProgramIcon}`, {
        collapsed: () => !state.loyaltyProgram?.pointDefinition?.icon?.url,
      });

      $bind(`#${ElementId.StatusText}`, {
        text() {
          const pointsName = customPointsName.get();

          if (!isProgramAvailable.get()) {
            return t('status.program-not-available');
          } else if (!state.isLoggedIn) {
            const amount = earnPointsAmount.get();

            if (pointsName) {
              return t('status.earn-points.custom', { amount, pointsName });
            } else {
              return t('status.earn-points', { amount });
            }
          } else {
            const amount = userPointsAmount.get();

            if (pointsName) {
              return t('status.you-have-points.custom', { amount, pointsName });
            } else {
              return t('status.you-have-points', { amount });
            }
          }
        },
      });

      $bind(`#${ElementId.EarnPointsText}`, {
        text() {
          const amount = earnPointsAmount.get();
          const pointsName = customPointsName.get();

          if (pointsName) {
            return t('earn-points.custom', { amount, pointsName });
          } else {
            return t('earn-points', { amount });
          }
        },
        collapsed: () => !state.isLoggedIn || !isProgramAvailable.get() || !canEarnPoints.get(),
      });

      $bind(`#${ElementId.ContentStates}`, {
        collapsed: () => !isProgramAvailable.get() || (state.isLoggedIn && !canRedeemPoints.get()),
      } as any);

      $bind(`#${ElementId.LogInText}`, {
        text: () => t('log-in.description'),
      });

      $bind(`#${ElementId.LogInButton}`, {
        label: () => t('log-in.button'),
        async onClick() {
          biLogger.logIn();
          state.isLoggedIn = await promptLogin(flowAPI);

          // NOTE: Checkout page needs a full browser reload after login
          if (state.isLoggedIn) {
            state.pendingRequestsCount++;
            wixCodeApi.location.to?.(wixCodeApi.location.url);
          }
        },
      });

      $bind(`#${ElementId.PointsNeededText}`, {
        text() {
          const nextReward = nextRewardDetails.get();
          const points = nextReward.missingUserPoints;
          const pointsName = customPointsName.get();
          let amount = '';

          if (nextReward.type === NextRewardType.Money) {
            amount = formatCurrency(nextReward.moneyAmountDiscount);
          } else if (nextReward.type === NextRewardType.Percentage) {
            amount = `${formatNumber(nextReward.percentageDiscount)}%`;
          }

          if (pointsName) {
            const translationKey =
              nextReward.type === NextRewardType.FreeShipping
                ? 'points-needed.free-shipping.custom'
                : 'points-needed.discount.custom';

            return t(translationKey, { points, amount, pointsName });
          } else {
            const translationKey =
              nextReward.type === NextRewardType.FreeShipping
                ? 'points-needed.free-shipping'
                : 'points-needed.discount';

            return t(translationKey, { points, amount });
          }
        },
      });

      $bind(`#${ElementId.PointsNeededProgressText}`, {
        text() {
          const userPoints = formatNumber(userPointsAmount.get());
          const nextRewardPoints = formatNumber(nextRewardDetails.get().costInPoints);

          return `${userPoints}/${nextRewardPoints}`;
        },
      });

      $bind(`#${ElementId.PointsNeededProgressBar}`, {
        targetValue: () => nextRewardDetails.get().costInPoints || 100,
        value: () => userPointsAmount.get(),
      });

      $bind(`#${ElementId.DiscountRewardPointsInput}`, {
        label() {
          const pointsName = customPointsName.get();

          if (pointsName) {
            return t('discount-reward.points-input.label.custom', { pointsName });
          } else {
            return t('discount-reward.points-input.label');
          }
        },
        placeholder: () =>
          t('discount-reward.points-input.placeholder', { points: maxDiscountRewardPointsAmount.get() }),
        value: () => state.discountRewardPointsValue,
        onInput(event) {
          runInAction(() => {
            state.isDiscountRewardFormTouched = true;
            state.hasFailedToApplyDiscountReward = false;
            state.showDiscountRewardOtherCouponError = false;
            state.discountRewardPointsValue = event.target.value;
          });
        },
        onBlur() {
          state.isDiscountRewardFormTouched = true;
        },
        onChange() {
          state.isDiscountRewardFormTouched = true;
        },
      });

      $bind(`#${ElementId.DiscountRewardError}`, {
        collapsed: () => !isDiscountRewardErrorVisible.get(),
      });

      $bind(`#${ElementId.DiscountRewardErrorText}`, {
        text: () => discountRewardErrorMessage.get() ?? '',
      });

      discountRewardPointsInput.onCustomValidation((value, reject) => {
        const errorMessage = discountRewardErrorMessage.get();
        if (errorMessage && !state.hasFailedToApplyDiscountReward) {
          reject(errorMessage);
        }
      }, true);

      $bind(`#${ElementId.DiscountRewardStatusText}`, {
        text() {
          const pointsName = customPointsName.get();
          const { costInPoints, discountPerPoint } = discountRewardConfig.get();
          const isValidEnteredPointsAmount = isValidDiscountRewardPointsValue.get();

          const enteredPointsAmount = discountRewardPoints.get();
          const pointsAmount = isValidEnteredPointsAmount ? enteredPointsAmount! : costInPoints;
          const calculatedDiscount = Math.round(pointsAmount * discountPerPoint * 100) / 100;
          const discountAmount = formatCurrency(calculatedDiscount);

          if (pointsName) {
            return t('discount-reward.status.custom', { pointsAmount, discountAmount, pointsName });
          } else {
            return t('discount-reward.status', { pointsAmount, discountAmount });
          }
        },
      });

      $bind(`#${ElementId.DiscountRewardRedeemButton}`, {
        label: () => t('discount-reward.redeem-reward'),
        disabled: () => !isValidDiscountRewardPointsValue.get(),
        async onClick() {
          state.hasFailedToApplyDiscountReward = false;
          const isValidEnteredPointsAmount = isValidDiscountRewardPointsValue.get();

          if (isOtherCouponCodeApplied.get()) {
            state.showDiscountRewardOtherCouponError = true;
          } else if (isValidEnteredPointsAmount) {
            try {
              state.pendingRequestsCount++;

              const enteredPointsAmount = discountRewardPoints.get()!;
              biLogger.getReward(enteredPointsAmount);

              await applyDiscountReward({
                flowAPI,
                pointsToSpend: enteredPointsAmount,
                discountReward: activeDiscountReward.get()!,
                checkoutId: $props.checkoutId,
              });

              await Promise.all([refreshCheckout?.(), loadCheckout()]);

              runInAction(() => {
                state.discountRewardPointsValue = '';
                if (state.loyaltyAccount?.points?.balance) {
                  state.loyaltyAccount.points.balance -= enteredPointsAmount;
                }
              });
            } catch (error) {
              state.hasFailedToApplyDiscountReward = true;
              errorMonitor?.captureException(error as Error);
              console.error(error);
            } finally {
              state.pendingRequestsCount--;
            }
          }
        },
      });

      $bind(`#${ElementId.CodeAppliedText}`, {
        text: () => t('code-applied'),
      });
    },
    exports: {
      onRefreshCheckout(refreshCheckoutCallback: () => Promise<void>) {
        refreshCheckout = refreshCheckoutCallback;
      },
    },
  };
});
