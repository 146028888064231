import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';

export interface State {
  pendingRequestsCount: number;
  hasFailedToLoad: boolean;
  isLoggedIn: boolean;
  checkout?: Checkout;
  loyaltyProgram?: LoyaltyProgram;
  loyaltyRewards?: LoyaltyReward[];
  loyaltyEarningRules?: LoyaltyEarningRule[];
  loyaltyAccount?: LoyaltyAccount;
  isDiscountRewardFormTouched: boolean;
  discountRewardPointsValue: string;
  hasFailedToApplyDiscountReward: boolean;
  showDiscountRewardOtherCouponError: boolean;
}

export const defaultState: State = {
  pendingRequestsCount: 1, // Always start rendering in a loading state
  hasFailedToLoad: false,
  isLoggedIn: false,
  checkout: undefined,
  loyaltyProgram: undefined,
  loyaltyRewards: undefined,
  loyaltyEarningRules: undefined,
  loyaltyAccount: undefined,
  isDiscountRewardFormTouched: false,
  discountRewardPointsValue: '',
  hasFailedToApplyDiscountReward: false,
  showDiscountRewardOtherCouponError: false,
};
